@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blink {
    animation: blink 1s infinite;
}

.iconcss{
color: #268ae1;
}
.iconcss:hover{
  color: #7e7f81;
    cursor: pointer;
}