.fade-in-box {
    
    animation: fadeIn 5s;
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .box{
    max-width: 360px;
    margin: 0 auto;
    padding: 0px;
    border:2px solid #3d3d8a;
    border-radius:15px;
    box-shadow:10px 10px 8px  #9d9d9d;
min-height:290px ;

   /* maxWidth: "360px",
    margin: "0 auto",
    padding: "0px",
    // backgroundColor:"green",
    border:"2px solid #3d3d8a",
    borderRadius:"15px",
    boxShadow:"10px 10px 8px  #9d9d9d",
    // boxShadow:"10px 10px 8px  #faa931",

    minHeight:"340px", */
  }
  .box:hover{
    border:3px solid #3d3d8a;
    border-radius:16px;
    
    /* cursor: pointer; */
  }